@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;300;400;500;700&display=swap');
@tailwind base;
@tailwind components;
@font-face {
  font-family: 'Miracle';
  src: local('Miracle'), url('/public/fonts/Miracle.otf') format('otf');
}
@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scroll::-webkit-scrollbar {
    display: none;
  }

  .no-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.morning {
  background-image: url('/public/images/morning.png');
  background-size: cover;
  background-position: 30%;

  background-repeat: no-repeat;
}

.morning_nonactive {
  background-image: url('/public/images/notactive/morning.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.main {
  background-image: url('/public/images/mainimage.png');
  background-size: cover;
  background-position: 20%;

  background-repeat: no-repeat;
}

.main_nonactive {
  background-image: url('/public/images/notactive/main.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.shivering {
  background-image: url('/public/images/shivering.png');
  background-size: cover;
  background-position: 30%;

  background-repeat: no-repeat;
}

.shivering_nonactive {
  background-image: url('/public/images/notactive/shivering.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.donald {
  background-image: url('/public/images/donald.png');
  background-size: cover;
  background-position: 30%;
  background-repeat: no-repeat;
}

.donald_nonactive {
  background-image: url('/public/images/notactive/donald.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.mainBanner_nonactive {
  background-image: url('/public/images/notactive/main.png');
  background-size: cover;
  background-repeat: no-repeat;
}

.ideal {
  background-repeat: no-repeat;
  background-size: cover;
}

.active {
  -webkit-filter: grayscale(0) !important;
  filter: grayscale(0) !important;
  max-height: 100vh;
  transition: width 0.5s ease-in-out, grayscale 5s, max-height 0.5s ease-in-out;
}
.noHover {
  pointer-events: none;
}

.gray {
  filter: gray; /* IE6-9 */
  -webkit-filter: grayscale(1); /* Google Chrome, Safari 6+ & Opera 15+ */
  filter: grayscale(1);
}

.ideal:hover {
  transition: width 0.5s ease-in-out, grayscale 5s;
  width: 150%;
  box-shadow: inset 0 0 0 1000px rgba(96, 255, 245, 0.1);
}

@media only screen and (max-width: 768px) {
  .ideal:hover {
    transition: width 0.5s ease-in-out, grayscale 5s;
    width: 100%;
  }
}

/* slides */
.wh {
  color: '#000' !important;
}
.korean-word {
  line-break: strict;
  word-break: keep-all;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

#spin {
  color: black;
}
#spin:after {
  content: '';
  animation: spin 1s linear infinite;
}
@keyframes spin {
  0% {
    content: '0xAD7ee295Bee34fd4D134192518958D73cb6B17cD';
  }
  15% {
    content: '0xfe9a5b97C71272F1a71a86f5196650B3702Cb5ed';
  }
  20% {
    content: '0xb13f97CAA5686a915a6922dee5f44418f37C9B63';
  }
  30% {
    content: '0xca8298A8f030B6041908b43C77E568F24E37BB7B';
  }
  35% {
    content: '0xeC2C1488CD375c5D7345e868DEb0e63F20a43046';
  }
  45% {
    content: '0xfbC091F3b12B2C5eC5353daFA9e3d6652d26782F';
  }
  60% {
    content: '0xa0610e244e24Fb7D8752492F519Cd5327edd8b00';
  }
  80% {
    content: '0xf3dACC28a5467127dE37fFEb8fD913E9fC898A88';
  }
  100% {
    content: '0xdF7E6DbCd821348CaC8159912A76CdEA4187dB05';
  }
}

.asset-link {
  display: none !important;
}

.asset-image .card {
  display: none !important;
}
